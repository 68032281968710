import { render, staticRenderFns } from "./ConfirmAmount.vue?vue&type=template&id=b3c50eca&scoped=true&"
import script from "./ConfirmAmount.vue?vue&type=script&lang=js&"
export * from "./ConfirmAmount.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmAmount.vue?vue&type=style&index=0&id=b3c50eca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3c50eca",
  null
  
)

export default component.exports