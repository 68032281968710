<template>
  <div class="mb-2">
    <div v-if="detailInfo">
      <div :class=" hasStepGuide ? 'in-process-step-guide' : 'top-up-in-process'">
        <top-up-success
          class="summary-form"
          :detail-info="detailInfo"
          :amount="detailInfo.amount"
          :local-bank-ticket-payment-info="localBankTicketPaymentInfo"
        />
      </div>
      <div
        class="user-hint mt-1 mb-2 d-flex"
        :class="hasStepGuide ? '' : 'justify-content-center'"
      >
        <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
      </div>
    </div>
    <div
      v-else-if="!showConfirmation"
      class="width-content mb-2"
    >
      <b-row class="add-fund-container mx-0">
        <b-col
          :md="hasStepGuide ? 12 : 9"
          class="p-0 card-content"
        >
          <div
            class="local-bank-top-up-amount p-0"
            :class="hasStepGuide ? 'mt-0' : 'mt-2'"
          >
            <!-- :is-toggle="isToggle" -->
            <top-up-amount
              :amount="amount"
              :amount-tip="amountTip"
              :percentage-tip="currentTip"
              :tip-index="currentTipIndex"
              :local-bank-ticket-payment-info="localBankTicketPaymentInfo"
              @on-confirm="handleShowConfirm"
            />
          </div>
        </b-col>
      </b-row>
      <div class="user-hint mt-1 mb-2 d-flex">
        <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
      </div>
    </div>

    <div
      v-else
      class="width-content"
    >
      <b-row class="add-fund-container mx-0">
        <b-col
          :md="hasStepGuide ? 12 : 9"
          class="card-content p-0"
        >
          <div
            class="local-bank-card-confirm-amount p-0"
            :class="hasStepGuide ? 'mt-0' : 'mt-2'"
          >
            <!-- :is-toggle="isToggle" -->
            <confirm-amount
              :transaction-info="transactionInfo"
              :amount="amount"
              :amount-tip="amountTip"
              :percentage-tip="currentTip"
              :tip-index="currentTipIndex"
              :billing="billing"
              :local-bank-ticket-payment-info="localBankTicketPaymentInfo"
              @on-prev="handlePrev"
              @handleShowSuccess="handleShowSuccess"
            />
          </div>
        </b-col>
      </b-row>
      <div class="user-hint mt-1 mb-2 d-flex">
        <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import TopUpAmount from '@/views/payment/components/LocalBankCard/TopUpAmount.vue'
import TopUpSuccess from '@/views/payment/components/LocalBankCard/TopUpSuccess.vue'
import envMixin from '@/mixins/envMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { PAYMENT_METHOD } from '@/constants'
import { createNamespacedHelpers } from 'vuex'
import ConfirmAmount from '@/views/payment/components/LocalBankCard/ConfirmAmount.vue'
import paymentMixin from '@/mixins/paymentMixin'
import UserHint from '@/components/UserHint.vue'

const { mapGetters } = createNamespacedHelpers('payment')
export default {
  components: {
    UserHint,
    BRow,
    BCol,
    TopUpAmount,
    TopUpSuccess,
    ConfirmAmount,
  },

  mixins: [envMixin, stepGuideMixin, paymentMixin],

  data() {
    return {
      detailInfo: null,
      showConfirmation: false,
      amount: 0,
      billing: [],
      transactionInfo: '',

      currentTip: null,
      amountTip: null,
      currentTipIndex: null,
    }
  },

  computed: {
    ...mapGetters(['listTicket']),

    localBankTicketPaymentInfo() {
      return this.paymentInfo?.localBankTicket?.additionalInfo[0]
    },
  },

  watch: {
    listTicket: {
      handler(val) {
        if (val) {
          const itemDetail = val?.content?.find(item => item?.details?.paymentMethod === PAYMENT_METHOD.LOCALBANK_TICKET && item.status === 'opening')
          this.detailInfo = itemDetail?.details
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    handleShowConfirm(data) {
      this.showConfirmation = true
      this.amount = data.amount
      this.currentTip = data.currentTip
      this.amountTip = data.tipAMAmount
      this.currentTipIndex = data.currentTipIndex
    },

    handlePrev(data) {
      this.showConfirmation = false
      this.amount = data.amount
      this.transactionInfo = data.transactionId
      this.billing = data.billing
      this.currentTip = data.currentTip
      this.amountTip = data.amountTip
    },

    handleShowSuccess(data) {
      this.isShowSuccessPage = data
      this.$emit('handleShowSuccessTabs', this.isShowSuccessPage)
    },
  },

}
</script>
